export const setUserType = (type) => {
    localStorage.setItem("UserType", type)
}
let userType = null;
export const getUserType = () => {
    if(userType > 0){
       return userType;
    }
    let userTypeStr = localStorage.getItem("UserType")
    if(null == userTypeStr || userTypeStr.length === 0){
       userType = 0;
    }else if(userTypeStr === "undefined"){
       localStorage.removeItem("UserType")
       userType = 0;
    } else if(userTypeStr === "ADMIN"){
       userType = 1;
    }else if(userTypeStr === "AGENT"){
       userType = 2;
    }else if(userTypeStr === "USER"){
       userType = 3;
    }else if(userTypeStr === "SUPER_ADMIN"){
       userType = 9;
    }else{
       userType = 0;
    }
    return userType;
}

export const isSystemUser = () => getUserType() === 1;
export const isAgentUser = () => getUserType() === 2;
export const isCustomerUser = () => getUserType() === 3;
export const isSuperSystemUser = () => getUserType() === 9;

export const isSuperLogin = () => {
    return window.location.pathname === "/system/login";
}

export const matchAuth = (auth) => {
    if(!auth){
       return true;
    }
    if(Array.isArray(auth)){
       return auth.indexOf(userType) >= 0;
    }
    return auth === userType;
}

export const setToken = (token) => {
    localStorage.setItem("token", token);
}
export const removeToken = () => {
    localStorage.removeItem("token");
}
let token = null;
export const getToken = () => {
    if(!token) {
        token = localStorage.getItem("token");
        if (null == token || token.length === 0) {
            return null;
        }
    }
    return token;
}

export const setAgreeShow = (state = "1") => {
    localStorage.setItem("agreeShow", state);
}
let agreeShow;
export const getAgreeShow = () => {
    if(agreeShow === undefined) {
        let agreeShowStr = localStorage.getItem("agreeShow");
        if (null == agreeShowStr || agreeShowStr.length === 0 || agreeShowStr === "1") {
            return true;
        }
    }
    return false;
}

export const setTenantId = (tenantId) => {
    if(!tenantId){
        return;
    }
    localStorage.setItem("tenantId", tenantId);
}
export const removeTenantId = () => {
    localStorage.removeItem("tenantId");
}
let tenantId = null;
export const getTenantId = () => {
    if(!tenantId) {
        tenantId = localStorage.getItem("tenantId");
        if (!tenantId || tenantId.length === 0 || tenantId === "undefined") {
            return undefined;
        }
    }
    return tenantId;
}

export const setAgentId = (agentId) => {
    if(!agentId){
        return;
    }
    localStorage.setItem("agentId", agentId);
}
export const removeAgentId = () => {
    localStorage.removeItem("agentId");
}
let agentId = null;
export const getAgentId = () => {
    if(!agentId) {
        agentId = localStorage.getItem("agentId");
        if (!agentId || agentId.length === 0 || agentId === "undefined") {
            return undefined;
        }
    }
    return agentId;
}

export const setInternational = (international) => {
    localStorage.setItem("international", international);
}
export const removeInternational = () => {
    localStorage.removeItem("international");
}
let international;
export const getInternational = () => {
    if(window._CONFIG.international){
       international = window._CONFIG.international;
    }else if(international === undefined) {
        let internationalStr = localStorage.getItem("international");
        if (!internationalStr || internationalStr.length === 0 || internationalStr === "undefined" || internationalStr === "0") {
            international = false
        }else{
            international = true;
        }
    }
    return international;
}

let lang = null;
export const getLang = () => {
    if(!lang) {
        lang = localStorage.getItem("lang");
        if (null == lang || lang.length === 0) {
            return "zh";
        }
    }
    return lang;
}

export const isChina = () =>{
    return getLang() === "zh"
}
