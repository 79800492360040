import * as umiRequest from "umi-request";
import apiConfig from '../config/api'
import {isChina, isSuperLogin, isSuperSystemUser, removeTenantId, getInternational, getTenantId, getToken, removeToken} from "./user";
import {navigateToLogin} from "./navigator";
import {message} from "antd";

export const getHeaders = () => {
    let headers = {};
    headers["Access-Control-Allow-Origin"] = "*"
    headers["Connection"] = "close";
    headers["Keep-Alive"] = "300";
    let token = getToken();
    if(token && token.length > 0){
       headers["Authorization"] = "Bearer " + token;
    }
    if(!isSuperSystemUser() && !isSuperLogin()) {
        let tenantId = getTenantId();
        if (tenantId > 0) {
            headers["tenantId"] = tenantId;
        }
    }
    headers["lang"] = isChina() ? "zh" : "en";
    return headers;
}

const request = umiRequest.extend({
    prefix: apiConfig.baseUrl,
    timeout: 60000,
    errorHandler: function(error) {
        console.log(error)
        if(error){
           if(error.message === "Failed to fetch"){
              message.warning("服务器请求失败(501)").then()
              return;
           }
           message.warning(error.message).then();
        }
    }
})

request.interceptors.request.use((url, options) => {
    let headers = getHeaders();
    if(headers){
       options.headers = Object.assign(options.headers, headers);
    }
    let international = getInternational();
    if(international) {
        if (options.params) {
            options.params.international = international ? 1 : 0;
        }
        if (options.data) {
            options.data.international =  international ? 1 : 0;
        }
    }
    return { url, options };
});

request.interceptors.response.use(async response => {
    const result = await response.clone().json();
    if (result.code === 401) {
        removeToken();
        removeTenantId();
        navigateToLogin()
    }else if(result.code === 1){
       throw new Error(result.message);
    }else{
       return result.data;
    }
});

export default request
