import React from "react";
import './loading.css';

const PageLoading = ({style = {}, title = "加载中，请稍后"}) => {
  return (
    <div id="loadingBox" style={style}>
      <div className="loading-inner">
        <div className="bsload bsload1" />
        <div className="bsload bsload2" />
        <div className="bsload bsload3" />
      </div>
      {title && <div className="loading-title">{title}</div>}
    </div>
  )
}
export default PageLoading
